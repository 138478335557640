<template>
<section class='newshome'>
   <div class="banner">
      <img :src="$t('locale')=='zh'?banner:banner_en" alt="" />
   </div>
   <section class="main_contain">
      <figure>
         <div
            v-for="value of showNewsList"
            :key="value.id"
            @mouseup="toNewsPage(value.title,value.id)">
            <div class="img_box">
               <img :src="picServeURL+value.image" alt="">
            </div>
            <article>
               <h1>{{value.title}}</h1>
               <p>{{value.publish_time}}</p>
               <span>{{value.brief}}</span>
            </article>
         </div>
         <section class="paging">
            <div class="block">
               <el-pagination
                  background
                  @current-change="clickSelect"
                  :current-page="pageNow"
                  layout="prev, pager, next, jumper"
                  :total="total">
               </el-pagination>
            </div>
         </section>
      </figure>
      <FooterC></FooterC>
   </section>
</section>
</template>

<script>
import FooterC from "./components/FooterC";
export default {
   components: {
      FooterC
   },
   data() {
      return {
         allNewsList: null,
         showNewsList: null,
         total: null,
         pageNow: 1,
         picServeURL: Global.Production,
         banner: require('../../assets/zh/images/new_banner@2x.jpg'),
         banner_en: require('../../assets/en/images/new_banner@2x.jpg'),
      }
   },
   methods: {
      clickSelect(val) {
         this.pageNow = val;
         this.getPageNews().then(res => {
            this.showNewsList = res.data.results
         })

      },
      toNewsPage(title, id) {
         this.$router.push({
            name: "NewsPage",
            params: {
               id
            },
         });
      },
      getPageNews() {
         return this.axios.get(Global.NEWS_PAGE_API + `${this.pageNow}`)
      },
   },
   created() {
      this.getPageNews().then((res) => {
         this.showNewsList = res.data.results
         this.total = Object.keys(res.data).length * 10;
      });
   },
};
</script>

<style lang="scss" scoped>
.newshome {
   margin-top: 70Px;
   background-color: #fff;
   word-break: break-word !important;

   .banner {
      width: 100%;
      height: 440Px;
      position: relative;
      overflow: hidden;

      img {
         display: block;
         position: absolute;
         left: 50%;
         transform: translate(-50%, 0);
         width: 1920Px;
         height: 440Px;
         margin: 0 auto
      }
   }

   .main_contain {
      background-color: #fff;
      position: absolute;
      top: 510Px;
      width: 100%;

      figure {
         width: 960Px;
         margin: 70Px auto 100Px;

         &>div {
            height: 214Px;
            margin: 35Px 0;

            &:hover {
               cursor: pointer;
            }

            .img_box {
               width: 271Px;
               height: 214Px;
               background-color: #ccc;
               position: relative;
               overflow: hidden;
               margin-right: 32Px;
               float: left;

               img {
                  height: 100%;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
               }
            }

            article {
               text-align: justify;
               display: flex;
               justify-content: space-around;
               flex-direction: column;
               height: 100%;

               h1 {
                  height: 66Px;
                  font-size: 24Px;
                  font-family:   PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #333300;
                  line-height: 33Px;
                  margin: 9Px 0 10Px;
                  white-space: normal;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
               }

               p {
                  height: 28Px;
                  font-size: 16Px;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  line-height: 28Px;
                  margin-bottom: 8Px;
               }

               span {
                  font-size: 16Px;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  line-height: 28Px;
                  white-space: normal;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
               }
            }
         }

         .paging {
            height: 64Px;
            background: #fff;
            border-radius: 4Px;
            margin-top: 72Px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .block {
               margin-right: 32Px;
            }
         }
      }
   }
}
</style>
